import defaultIcons from '@elegantstack/gatsby-common-helpers/src/default.social.icons'
import { FaEthereum } from 'react-icons/fa'

export default {
  ...defaultIcons,
  ens: {
    Icon: FaEthereum,
    color: '#2C46A6'
  }
}
