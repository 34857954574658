import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Card } from 'theme-ui'

const CookiePolicy = props => (
  <Layout {...props}>
    <Seo title='Cookie Policy' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Cookie Policy"
          subheader="Last updated: January 2025"
        />
        <Divider />
        <Card>
          <h2>1. What Are Cookies</h2>
          <p>Cookies are small text files that are placed on your computer or mobile device when you visit our website. They are widely used to make websites work more efficiently and provide useful information to website owners.</p>

          <h2>2. How We Use Cookies</h2>
          <p>We use cookies for the following purposes:</p>
          <ul>
            <li><strong>Essential Cookies:</strong> Required for the website to function properly</li>
            <li><strong>Analytics Cookies:</strong> Help us understand how visitors interact with our website</li>
            <li><strong>Preference Cookies:</strong> Remember your settings and preferences</li>
            <li><strong>Marketing Cookies:</strong> Track your visits to our website and help deliver relevant content</li>
          </ul>

          <h2>3. Types of Cookies We Use</h2>
          <ul>
            <li><strong>Session Cookies:</strong> Temporary cookies that expire when you close your browser</li>
            <li><strong>Persistent Cookies:</strong> Remain on your device until they expire or you delete them</li>
            <li><strong>Third-Party Cookies:</strong> Set by third-party services we use, such as Google Analytics</li>
          </ul>

          <h2>4. Managing Cookies</h2>
          <p>You can control and manage cookies in various ways:</p>
          <ul>
            <li>Change your browser settings to accept or refuse cookies</li>
            <li>Delete cookies that have already been set</li>
            <li>Choose to opt-out of specific third-party cookies</li>
          </ul>

          <h2>5. Impact of Disabling Cookies</h2>
          <p>Please note that disabling certain cookies may impact the functionality of our website. Essential cookies cannot be disabled as they are necessary for the website to work properly.</p>

          <h2>6. Updates to This Policy</h2>
          <p>We may update this Cookie Policy from time to time. Any changes will be posted on this page with an updated revision date.</p>

          <h2>7. Contact Us</h2>
          <p>If you have questions about our Cookie Policy, please contact us through our contact form.</p>
        </Card>
      </Main>
    </Stack>
  </Layout>
)

export default CookiePolicy
