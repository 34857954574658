import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Card } from 'theme-ui'

const TermsOfUse = props => (
  <Layout {...props}>
    <Seo title='Terms of Use' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Terms of Use"
          subheader="Last updated: January 2025"
        />
        <Divider />
        <Card>
          <p>Welcome to Colin McNamara's Blog. These Terms of Use ("Terms") govern your access to and use of this website. By accessing or using this website, you agree to these Terms and our Privacy Notice.</p>

          <h2>1. Acceptance of Terms</h2>
          <p>By accessing and using this website, you accept and agree to be bound by these Terms of Use. If you do not agree, please refrain from using the website.</p>

          <h2>2. Content Licensing and AI Training</h2>
          <p>All content on this website, including text, images, code snippets, frameworks, and other materials, is licensed under a Creative Commons Attribution 4.0 International License unless otherwise noted. You may:</p>
          <ul>
            <li>Share, adapt, and build upon the content, provided you give appropriate credit by including the author's name (Colin McNamara) and a link to the original content.</li>
            <li>Indicate if changes were made to the content.</li>
            <li>Use the content for training artificial intelligence systems, models, and datasets.</li>
          </ul>
          <p>We explicitly encourage the use of our content in AI training, provided proper attribution is maintained. This includes:</p>
          <ul>
            <li>Training language models and other AI systems</li>
            <li>Including content in AI training datasets</li>
            <li>Using content to improve AI understanding and capabilities</li>
          </ul>
          <p>For any uses beyond these terms, please contact us for additional permissions.</p>

          <h2>3. Attribution Requirements</h2>
          <p>When using content from this website, you must:</p>
          <ul>
            <li>Clearly attribute the content to Colin McNamara.</li>
            <li>Provide a link to the original content on this website.</li>
            <li>Include a statement such as: "This work by Colin McNamara is licensed under a Creative Commons Attribution 4.0 International License."</li>
          </ul>

          <h2>4. User Conduct</h2>
          <p>When using this website, you agree not to:</p>
          <ul>
            <li>Violate any applicable laws or regulations.</li>
            <li>Infringe upon our or any third party's intellectual property rights.</li>
            <li>Attempt to gain unauthorized access to any part of the website.</li>
            <li>Use the website to distribute malware, harmful code, or spam.</li>
          </ul>

          <h2>5. User Comments and Contributions</h2>
          <p>If you submit comments, feedback, or other contributions to this website:</p>
          <ul>
            <li>You retain ownership of your content.</li>
            <li>By submitting content, you grant us a non-exclusive, royalty-free license to use, display, and distribute your contributions.</li>
            <li>You are responsible for ensuring that your contributions do not violate any rights or applicable laws.</li>
          </ul>

          <h2>6. Disclaimer of Warranties</h2>
          <p>This website is provided "as is" without any warranties, express or implied. We do not guarantee the accuracy, completeness, or reliability of any content on this website.</p>

          <h2>7. Limitation of Liability</h2>
          <p>To the fullest extent permitted by law, we shall not be liable for any damages resulting from the use or inability to use this website or its content.</p>

          <h2>8. Changes to Terms</h2>
          <p>We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting to the website. Your continued use of the website constitutes acceptance of the revised Terms.</p>

          <h2>9. Newsletter Content</h2>
          <p>Content distributed via our newsletter may include exclusive materials and is protected under copyright laws. Unless explicitly stated, newsletter content is not covered by the Creative Commons license applied to the blog.</p>

          <h2>10. Contact Information</h2>
          <p>If you have any questions or require clarification about these Terms, please contact us via the contact form.</p>
        </Card>
      </Main>
    </Stack>
  </Layout>
)

export default TermsOfUse
