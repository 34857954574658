import React from 'react'
import { Card, Text, Box, Label, Input, Button } from 'theme-ui'
import Section from '@components/Section'

const NewsletterCompact = props => (
  <Section aside title='Newsletter' {...props}>
    <Card variant='paper'>
      <Text variant='p' sx={{ mb: 3 }}>
        Subscribe to my newsletter, and get information you won't find on social media
      </Text>
      <Box
        as="form"
        action="https://buttondown.com/api/emails/embed-subscribe/colinmcnamara"
        method="post"
        className="embeddable-buttondown-form"
      >
        <Label htmlFor="email" sx={{ mb: 2 }}>Email</Label>
        <Input 
          type="email"
          name="email"
          id="email"
          placeholder="you@example.com"
          sx={{ mb: 3 }}
        />
        <Input type="hidden" value="1" name="embed" />
        <Input 
          type="submit" 
          value="Subscribe"
          sx={{
            variant: 'buttons.primary',
            display: 'block',
            width: '100%',
            mt: 3
          }}
        />
      </Box>
    </Card>
  </Section>
)

export default NewsletterCompact
