import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Card } from 'theme-ui'

const PrivacyPolicy = props => (
  <Layout {...props}>
    <Seo title='Privacy Notice' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Privacy Notice"
          subheader="Last updated: January 2025"
        />
        <Divider />
        <Card>
          <h2>1. Information We Collect</h2>
          <p>We collect information that you provide directly to us, including when you subscribe to our newsletter or contact us. This may include:</p>
          <ul>
            <li>Name and email address</li>
            <li>Messages you send through our contact form</li>
          </ul>

          <h2>2. Automatically Collected Information</h2>
          <p>When you visit our website, we automatically collect certain information about your device and usage of the site, including:</p>
          <ul>
            <li>Browser type and version</li>
            <li>Operating system</li>
            <li>Pages visited and time spent</li>
            <li>IP address</li>
          </ul>

          <h2>3. How We Use Your Information</h2>
          <p>We use the information we collect to:</p>
          <ul>
            <li>Provide and maintain our website</li>
            <li>Send you updates and newsletters you've subscribed to</li>
            <li>Respond to your comments and questions</li>
            <li>Analyze and improve our website performance</li>
          </ul>

          <h2>4. Information Sharing</h2>
          <p>We do not sell or rent your personal information to third parties. We may share your information with:</p>
          <ul>
            <li>Service providers who assist in operating our website</li>
            <li>Analytics partners to understand website usage</li>
            <li>Law enforcement when required by law</li>
          </ul>

          <h2>5. Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal information</li>
            <li>Correct inaccurate information</li>
            <li>Request deletion of your information</li>
            <li>Opt-out of communications</li>
          </ul>

          <h2>6. Contact Us</h2>
          <p>If you have questions about this Privacy Notice, please contact us through our contact form.</p>
        </Card>
      </Main>
    </Stack>
  </Layout>
)

export default PrivacyPolicy
