import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader='Lifelong problem-solver combining deep technical expertise, business leadership, and hands-on innovation to tackle complex challenges—using cutting-edge AI to create meaningful solutions for businesses and communities'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button as={GLink} to='/contact' sx={styles.button}>
              Contact Me
            </Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>
                  My journey into AI began when a friend shared his experience using an early research version
                  of GPT-2 for billion-dollar contract synthesis. This revelation, combined with Harrison Chase's
                  Chat with Data Challenge, led me to explore LangChain for generating investment white papers. 
                  Not only did it streamline processes, but it also helped uncover potential fraud that could 
                  have led to SEC violations. Excited by these results, I began sharing my code and connecting 
                  with others in the community, which ultimately led to my role as a LangChain Ambassador.
                </Text>
                <Text variant='p'>
                  As Head of Engineering and Finance at Always Cool Brands, I've witnessed firsthand how AI 
                  has revolutionized our entire business operations. We've transformed traditional business 
                  processes into dynamic, intelligent systems that adapt and learn. This integration of AI 
                  across our organization has enhanced our forecasting accuracy, streamlined operations, and 
                  enabled data-driven decision making at every level. The impact has been transformative, 
                  dramatically improving both the quality of our work and the speed at which we can execute 
                  in ways that would have been impossible just a few years ago.
                </Text>
                <Text variant='p'>
                  A defining moment in my leadership journey occurred during my deployment into the Third Marine 
                  Air Wing in 2003. When a virus crippled the network during a critical combined arms exercise, 
                  leadership found me—a firefighter with a background in network consulting—to help restore 
                  operations. This experience taught me that rank and title matter less than respect and 
                  preparation, a principle I later applied at Oracle where my team achieved a 51% reduction in 
                  outage minutes across 64 data centers across the globe powering Oracles SaaS applications.
                </Text>
                <Text variant='p'>
                  The Austin LangChain AIMUG (AI Middleware User Group) emerged from my desire to learn and 
                  grow alongside others passionate about AI innovation. What started as a small group has evolved 
                  into a thriving community of over 1,000 members. We're now expanding into hackathons, 
                  collaborative projects, and conference presentations, with seven of our members presenting at 
                  MLOps World. Our success stories include members who've transformed from fearing job 
                  instability to becoming vital assets in their organizations.
                </Text>
                <Text variant='p'>
                  Through Key Bank's Mosaic team, I consult with investment banks and fund managers to help them 
                  understand technology's impact on their investment sectors and portfolio companies. This unique 
                  perspective at the intersection of finance and technology has been invaluable in shaping my 
                  approach to direct-to-consumer (D2C) strategies at Always Cool Brands. The synergy between 
                  high-level strategic consulting and hands-on business operations continually pushes me to 
                  refine my strategies and share insights with brilliant peers.
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='AI Vision & Innovation'>
                <Card variant='paper'>
                  AI is woven into everything we do—from Grammarly for communication to fine-tuned 
                  LoRa adapters, and everything in between. As a LangChain Ambassador, I focus on fostering 
                  collaboration, sharing knowledge, and advocating for responsible, practical AI applications. 
                  Our approach at Always Cool Brands demonstrates how AI can save time and money at every 
                  level while allowing us to scale our impact well beyond our current staffing. This 
                  integration enables us to streamline processes, focus on higher-level problem-solving, 
                  and continue driving innovation in sustainable supply chain solutions.
                </Card>
              </Section>
              <Divider />
              <Section title='Community & Education'>
                <Card variant='paper'>
                  The Austin LangChain AIMUG embodies our commitment to "learning in the open." We're 
                  partnering with local colleges to mentor students and bridge the gap between academia 
                  and industry. Our community's growth from a handful of enthusiasts to over 1,000 members 
                  reflects the power of collaborative learning. We're expanding into hackathons, fostering 
                  team projects, and speaking at major conferences, all while maintaining our core philosophy 
                  of open knowledge sharing and mutual support.
                </Card>
              </Section>
              <Divider />
              <Section title='Future of Supply Chain'>
                <Card variant='paper'>
                  I envision a future where barcode data becomes a gateway to comprehensive product 
                  information—from provenance and composability to health benefits and producer stories. 
                  AI is democratizing access to this information, enabling real-time queries from consumers 
                  while helping businesses ensure compliance and optimize sustainability. At Always Cool 
                  Brands, we're working to eliminate harmful dyes, additives, and unnecessary ingredients 
                  from our food supply through AI-powered analysis, tracking, and compliance monitoring. 
                  This technology-driven approach is key to building consumer trust and creating a 
                  healthier food ecosystem.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
