import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader="As a LangChain Ambassador and Head of Engineering at Always Cool Brands, I integrate AI into every aspect of operations—from fine-tuned models to sustainable supply chain solutions. Through the Austin LangChain AIMUG, I foster a community of over 1,000 members dedicated to open learning and innovation. Whether it's AI development, supply chain optimization, or building sustainable solutions, I'm passionate about collaborating on meaningful challenges. If you share these goals or see opportunities for synergy, I'd love to hear from you."
          />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
